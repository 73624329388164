import React from 'react';

import { useMemo, useEffect } from 'react';

import Polyline from '../../../../components/maps/Polyline';

import useRouteCoordinates from '../../../../hooks/useRouteCoordinate';

import './GpsgateCoordinates.css';

const GpsgatePath = ({ route_id }) => {
    const { coordinates = [], fetchCoordinates } = useRouteCoordinates();

    useEffect(() => {
        fetchCoordinates(route_id);
    }, [ route_id, fetchCoordinates ]);
    

    const normalPath = useMemo(() => {

        if (coordinates === null) {
            return [];
        }

        return coordinates.reduce((normalPath, { latitude, longitude }) => {  
            normalPath.push({ lat: latitude, lng: longitude });
            return normalPath;
        }, []);

    }, [ coordinates ]);
    
    return (
        <>

            {
                normalPath
                &&
                <Polyline 
                    path={normalPath} 
                    strokeOpacity={1} 
                    strokeColor={'#57004e'}
                    strokeWeight={3}
                    icons={[{
                        icon: {
                            path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                            scale: 2.5
                        },
                        repeat: '200px'
                  }]}
                />
             
            }
        </>
    )

}

export default GpsgatePath;
