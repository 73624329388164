
export function toCurrency(value, sign = true) {
    if (value == undefined) return '';
    
    return (sign ? '€' : '') + Number(value).toFixed(2).replace(/\./g, ',');
}

export function dateFormat(date, time = true) {
    if (date == undefined) return '';
    
    const date_format = ("0" + date.getDate()).slice(-2) + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + date.getFullYear();
    const time_format = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);

    return date_format + (time ? ' ' + time_format : '');
}

export function timeFormat(date) {
    if (date == undefined) return '';
    return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
}

export function msToTime(ms) {
    var seconds = Math.floor((ms / 1000) % 60);
    var minutes = Math.floor((ms / (60 * 1000)) % 60);
    var hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    return hours + ":" + ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);
}

export function convertLicenseplate(licenseplate) {
    if (licenseplate === null || licenseplate === undefined) return '';
    return licenseplate.replace(/-/g, '').replace(/ /g, '').toUpperCase();
}

// lat 2 insde lat 1
export const inside = (lat1, lon1, lat2, lon2, radius=50) =>  {

    const R = 63710; // Earth's radius in m

    return Math.acos(Math.sin(lat1)*Math.sin(lat2) + 
                    Math.cos(lat1)*Math.cos(lat2) *
                    Math.cos(lon2-lon1)) * R < radius;
}

/*
    'M' is Meters
    'K' is kilometers

*/

export const distance = (lat1, lon1, lat2, lon2, unit="k") => {

	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	} else {

		let radlat1 = Math.PI * lat1/180;
		let radlat2 = Math.PI * lat2/180;
		let theta = lon1-lon2;
		let radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        
		if (dist > 1) {
			dist = 1;
        }
        
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        
        if (unit=="k") { dist = dist * 1.609344 }
        if (unit=="m") { dist = dist * 1609.344 }
 
		return dist;
    }
    
}
// Pad a number to 2 digits
const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');

// Get timezone offset in ISO format (+hh:mm or -hh:mm)
const getTimezoneOffset = (date) => {
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60);
};

export const toISOStringWithTimezone = (date) => {
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        getTimezoneOffset(date);
};