import React, { Component } from 'react';

import { withRouter }       from 'react-router-dom';
import { connect }          from 'react-redux';

import {
    userLogin
}                           from '../../redux/user/actions';

import Activityindicator    from '../../components/activityindicator/Activityindicator';

import Dashboard            from '../../lib/api/Dashboard';

import './Login.css';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',

            username_error: false,
            password_error: false
        }

    }

    componentDidMount() {
        const { authenticated, history, branche } = this.props;

        if (authenticated) {
            if (branche) {
                history.push('/groothandels/'+branche.id);
            } else {
                history.push('/groothandels');
            }
        }

    }

    onChange = (event) => {
        const { name, value } = event.target

        this.setState({
            [name]: value,
            [name + '_error']: (value === '' )
        })
    }

    onSubmit = (event) => {
        const { username, password } = this.state;
        const { dispatch, history } = this.props;

        event.preventDefault();

        if (username !== '' && password !== '') {
            dispatch(userLogin(username, password)).then((response) => {
                const { type } = response;

                if (type === 'USER_SUCCESS') {
                    const { user } = response;

                    if (user.branche) {
                        history.push('/groothandels/'+user.branche.documentId);
                    } else {
                        history.push('/groothandels');
                    }
                } 
            
            });
        } else {
            this.setState({
                username_error: (username === '' ),
                password_error: (password === '' ),
            })
        }
   
    }

    render() {
        const { username, username_error, password, password_error } = this.state;
        const { loading, error } = this.props;

        return (
            <div className="login">

                <img className="logo"src="/images/logo.png" />

                <div className="form"> 
                    <h2>Inloggen</h2>
                    {
                        loading
                        ?
                            <div className="loading"><Activityindicator /></div>
                        :
                            <form onSubmit={this.onSubmit} >
                                {
                                    error
                                    &&
                                    <p className="error_message">Inloggen niet gelukt</p>
                                }
                                <input type="text" name="username" placeholder="Gebruikersnaam" value={username} className={(username_error ? 'error' : '')} onChange={this.onChange} />
                                <input type="password" name="password" placeholder="Wachtwoord" value={password} className={(password_error ? 'error' : '')} onChange={this.onChange} />
                                <button type="submit">Inloggen</button>
                            </form> 
                    }
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {	
    const { user: { authenticated, loading, error, user  } } = state;

	return {
        authenticated: authenticated,
        loading: loading,
        error: error,
        branche: (user ? user.branche : null)
	};
}

export default connect(mapStateToProps)(withRouter(Login));