import React, { Component } from 'react';

import { useState, useMemo, useEffect } from 'react';

import useRouteCoordinates from '../../../../hooks/useRouteCoordinate';

import Marker from '../../../../components/maps/Marker';
import Polyline from '../../../../components/maps/Polyline';

import './GpsgateCoordinates.css';

const GpsgateCoordinates = ({ route_id }) => {
    const { coordinates = [], fetchCoordinates } = useRouteCoordinates();
    const [ show, setShow ] = useState(false);
    const [ stopsOnly, setStopsOnly ] = useState(true);

    useEffect(() => {
        if (show) {
            fetchCoordinates(route_id);
        }
    }, [ route_id, show, setShow ]);

    return (
        <>
            <div className={"crossyn-container"}>
                <div>
                    <input type="checkbox" id="stopsOnly" name="stopsOnly" value="true" checked={stopsOnly} onChange={() => { setStopsOnly(!stopsOnly) }} />
                    <label htmlFor="stopsOnly">Stops only</label>
                </div>
                <button className={"crossyn-data" + (show ? ' crossyn-data-show' : '')} onClick={() => { setShow(!show) }}>GPS data</button>
              
            </div>

            {
      
                show && coordinates !== null
                &&
                coordinates.map((coord, index) => {
                    const { latitude, longitude, speed, positionUpdated, updatedTime } = coord || {};
          
                    if (stopsOnly && Math.round(speed) !== 0) { return null; }
            
                    return (
                        <Marker
                            key={'c2'+positionUpdated+'-'+latitude+'-'+longitude + '-'+ index}
                             icon={{ fillColor: (Math.round(speed) !== 0 ? '#29235c' : 'red'), scale: 1 }}
                            position={ {
                                latitude: latitude,
                                longitude: longitude
                            }}
                            zIndex={9999}
                            hideZoomLevel={14}
                            infoWindowContent={
                                <div className='infowindow'>
                                    <span style={{fontWeight:'bold'}}>Time:</span> { new Date(updatedTime).toLocaleTimeString() } <br/>
                                    <span style={{fontWeight:'bold'}}>Speed:</span> { Math.round(speed) } <br/>
                                </div>
                            }
                        />
                    );
                })
        
            }

        </>
    );
}

export default GpsgateCoordinates;