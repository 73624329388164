import WebService from '../webservice/WebService';
import Subscriptions2 from '../subscriptions/Subscriptions2';

import qs from 'qs';

class Dashboard {

    constructor() {

        const url = process.env.REACT_APP_DASHBOARD_URL;
        const path = process.env.REACT_APP_DASHBOARD_API_PATH;

        this.notAuthorized = () => {};

        this.webservice = new WebService({
            url: url + (path ? path : '')
        });

        this.webservice.onResponse(({ status }) => {
            if (status === 401) {
                this.notAuthorized();
            }
        });

        this.subscriptions = new Subscriptions2({
            url: url,
            path: (path ? path : '')
        });
    
    }

    setToken = (token) => {
        this.webservice.addHeader('Authorization', 'Bearer ' + token);
        this.subscriptions.open(token);
    }

    removeToken = () => {
        this.webservice.removeHeader('Authorization');
        this.subscriptions.close();
    }

    subscribe = (...args) => {
        return this.subscriptions.subscribe(...args);
    }

    onNotAuthorized = (callback) => {
        this.notAuthorized = callback;  
    }

    login = (identifier, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { jwt } = await this.webservice.post('/auth/local', { identifier: identifier, password: password });

                this.setToken(jwt);

                const query = qs.stringify({
                    populate: [ 'branche' ], 
                }, {
                    encodeValuesOnly: true
                });

                const user = await this.webservice.get(`/users/me?${query}`);

                resolve({
                    jwt,
                    user
                })
            } catch (error) {
                reject(error);
            }
        });
   
    }

    me = () => {
        const query = qs.stringify({

            populate: [ 'branche' ], 
 
        }, {
            encodeValuesOnly: true
        });
        return this.webservice.get(`/users/me?${query}`);
    }

    getBranches = () => {
        return this.webservice.get('/branches?dashboard=true');
    }

    getBranche = (id) => {
        return this.webservice.get('/branches/' + id);
    }

    getRoutes = (params) => {

        const query = qs.stringify({
            filters: params,
            populate: {
                activities: {
                    fields: [ 'activity_status', 'active', 'status_history' ]
                }
            }, 
            sort: [ 'number' ],
        }, {
            encodeValuesOnly: true
        });

        return this.webservice.get(`/routes?${query}`);
    }

    getRoute = (id) => {
        
        const query = qs.stringify({
            populate: [ 'branche', 'activities' ]
        },{
            encodeValuesOnly: true
        })
          
        return this.webservice.get(`/routes/${id}?${query}`);
    }

    getRoutePath = (id) => {
        return this.webservice.get(`/routes/${id}/path`);
    }

    getRouteCoordinates = (route_id) => {
    
        const query = qs.stringify({
            filters: {
                route_id: {
                    $eq: route_id
                }
            }
        },{
            encodeValuesOnly: true
        })
          
        return this.webservice.get(`/routes-coordinates?${query}`);
    }

    getTrips = (licenseplate, params) => {
        return this.webservice.get('/vehicles/' + licenseplate + '/trips', params);
    }

    getVehicles = (params) => {

        return this.webservice.get('/vehicles?pagination[pageSize]=1000', {});
    }

    getVehiclePosition = (licenseplate) => {
        return this.webservice.get('/vehicles/' + licenseplate + '/position');
    }

    getPois = () => {
        return this.webservice.get('/pois');
    }

    search = (type, query) => {
        return this.webservice.get('/routes/search', { q: query, type: type });
    }

    getBehaviour = (driverId, driverHanosStoreNumber, licensePlate, startdate ) => {
        return this.webservice.post('/hanos/getBehavior', driverId, driverHanosStoreNumber, licensePlate, startdate );
    }
}

export default new Dashboard();