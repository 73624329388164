const initialState = {
    
    data: {},

    error: null,
    loading: false,
    lastUpdate: null
};

export default function routeDetails(state = initialState, action) {

    switch (action.type) {

        case 'ROUTE_REQUEST':

            return Object.assign({}, state, {
                error: null,
                loading: true
            });

        case 'ROUTE_SUCCESS':

            var { lastUpdate } = action;
            var { data={} } = action.data || {};

            // Add empty Crossyn trips data
            data.crossyn_trips = null;

            return Object.assign({}, state, {
                data: data,
                error: null,
                loading: false,
                lastUpdate: lastUpdate
            });

        case 'ROUTE_ERROR':

            var { error } = action;

            return Object.assign({}, state, {
                error: error,
                loading: false
            });

        case 'ROUTE_UPDATE':
            var { data={} } = action.data || {};

            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    ...data
                }
            });

        case 'ROUTE_ACTIVITY_UPDATE':
            var { data={} } = action.data || {};
            
            var activities = [...state.data.activities];


            var index = activities.findIndex((activity) => {
                return (activity.activity_id === data.activity_id);
            });

            if (index !== -1) {

                activities[index] = {
                    ...activities[index],
                    ...data
                }
            }

            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    activities: activities
                }
            });

        case 'ROUTE_TRIPS_UPDATE':
            var { data=[] } = action || {};

            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    crossyn_trips: data
                }
            });
    
        default:
            return state;
        
    }
    
}
