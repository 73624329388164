const initialState = {
    
    data: {},
    list: [],

    error: null,
    loading: false,
    lastUpdate: null
};

export default function routeList(state = initialState, action) {

    switch (action.type) {

        case 'ROUTES_REQUEST':

            return Object.assign({}, state, {
                error: null,
                loading: true
            });

        case 'ROUTES_SUCCESS':

            var { lastUpdate } = action;
            var { data=[] } = action.data || {};

            var route_data = {};
            var route_list = [];

            data = data.filter(({ activities = [] }) => {
                return activities?.length > 0;
            });

            data.forEach((item) => {
                route_data[item.documentId] = {...item};
                route_list.push(item.documentId);
            });

            return Object.assign({}, state, {
                data: route_data,
                list: route_list,
                error: null,
                loading: false,
                lastUpdate: lastUpdate
            });

        case 'ROUTES_ERROR':

            var { error } = action;

            return Object.assign({}, state, {
                error: error,
                loading: false
            });

        
        case 'ROUTE_ADD':

            var { data={} } = action.data || {};
            
            var route_data = {
                ...state.data,
                [data.documentId]: data
            }

            var route_list = [
                ...state.list,
                data.documentId
            ];

            return Object.assign({}, state, {
                data: route_data,
                list: route_list
            });
        
        case 'ROUTE_UPDATE':

            var { data={} } = action.data || {};
            
            var route_data = {
                ...state.data
            }

            if (route_data[data.documentId]) {
                route_data[data.documentId] = {
                    ...state.data[data.documentId],
                    ...data
                }
            }

            return Object.assign({}, state, {
                data: route_data
            });

        case 'ROUTE_UPDATE_ACTIVITY':

            var { route_id } = action;
            var { data={} } = action.data || {};

            var route_data = state.data;

            if (route_data[route_id]) {
                
                var activities = [...state.data[route_id].activities];

                var index = activities.findIndex((activity) => {
                    return activity.id === data.id;
                });
                
                if (index !== -1) {
                    activities[index] = {
                        ...activities[index],
                        ...data
                    };
                }

                var route_data = {
                    ...state.data,
                    [route_id]: {
                        ...state.data[route_id],
                        activities: activities
                    }
                };
            }

            return Object.assign({}, state, {
                data: route_data
            });


        default:
            return state;
        
    }
    
}
