import { useCallback } from 'react';

import Dashboard from '../lib/api/Dashboard';

import useRequest from './useRequest';

const useRouteCoordinates = () => {

    const { data = [], error, loading, setRequest, setSuccess, setError } = useRequest();

    const fetchCoordinates = useCallback(async (id) => {
        try {

            setRequest();

            const { data } = await Dashboard.getRouteCoordinates(id);
            const { gpsgate_stream_coordinates = [] } = data[0] || {}

            setSuccess(gpsgate_stream_coordinates);

            return gpsgate_stream_coordinates;

        } catch (error) {
            console.log('[useRouteCoordinates] addToCart', error);
            setError(error);
        }

    }, []);

    return {
        error,
        loading,
        coordinates: data,
        fetchCoordinates
    };
}

export default useRouteCoordinates;
