import React, { Component } from 'react';

import { connect }          from 'react-redux';

import { withRouter }       from 'react-router-dom';

import { getBranche }       from '../../../redux/branches/details/actions';
import { 
    addRoute,
    updateRoute,
    getRoutes
}                           from '../../../redux/routes/list/actions';

import Page                 from '../../../components/page/Page';
import Map                  from '../../../components/maps/Map';
import Marker               from '../../../components/maps/Marker';
import RouteLayer           from '../../../components/maps/layers/RouteLayer';
import ErrorState           from '../../../components/state/ErrorState';
import LoadingState         from '../../../components/state/LoadingState';

import Dashboard            from '../../../lib/api/Dashboard';

import RouteInfo            from './info/RouteInfo';

class Details extends Component {
    
    componentDidMount() {
        
        const { dispatch, match: { params } } = this.props;

        if (params.branche_id) {
            dispatch(getBranche(params.branche_id)).then((action) => {
            
                const { error } = action;
                const { data={} } = action.data || {};

                if (data) {

                    const now = new Date();
                    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

                    dispatch(getRoutes({
                        branch_nummer: data.number,
                        createdAt: { $gte: today.getTime() }
                    }));

                    const id = data.id;

                    this.dashboard_route_created = Dashboard.subscribe('/route/created', { branche: { id:data.id} }, (data) => {
                        dispatch(addRoute(data));
                    });

                    this.dashboard_route_updated = Dashboard.subscribe('/route/updated', {branche: { id:data.id }}, (data) => {
                        dispatch(updateRoute(data));
                    });
                }
            });
        }
    }

    componentWillUnmount() {

        if (this.dashboard_route_created) {
            this.dashboard_route_created.remove();
        }

        if (this.dashboard_route_updated) {
            this.dashboard_route_updated.remove();
        }
    }

    renderRouteLayers() {
        const { data, route_list } = this.props;

        if (!route_list.length) { return null; }
        
        const route_layers = route_list.map((route_id) => {
            return (
                <RouteLayer
                    key={route_id}
                    route_id={route_id}
                    stops={false}
                    onClick={() => {
                        this.props.history.push('/groothandels/' + data.documentId + '/routes/' + route_id);
                    }}
                />
            );
        });

        return route_layers;
    }

    renderMap() {

        const { data } = this.props;
        const { latitude, longitude, color } = data;

        return (
            <Map
                zoom={10}
                panX={-200}
                center={{
                    latitude: latitude,
                    longitude: longitude
                }}
            >
                <Marker
                    position={{
                        latitude: latitude,
                        longitude: longitude
                    }}
                    icon={{
                        path: 'M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z',
                        fillColor: color,
                    }}
                />

                { this.renderRouteLayers() }

            </Map>
        );
    }

    onBack = () => {

        const { history } = this.props;
        history.push('/groothandels');
    }

    render() {

        const { data, error, loading } = this.props;

        if (loading) { return <LoadingState label="Bezig met laden..." />; }
        if (error) { return <ErrorState label="Er is een fout opgetreden" message={error.message} />; }

        if (!Object.keys(data).length) { return null; }
        
        return (
            <Page onBack={this.onBack}>

                <div style={ {position: 'relative', height:'100%'} }>

                    <div className="sidebar">
                        
                        <div className="card">
                            <h2 style={ {margin:0, padding:20} }>{data.name}</h2>
                        </div>

                        <RouteInfo branche_id={data.documentId} />

                    </div>

                    { this.renderMap() }

                </div>

            </Page>
        );
    }
}

function mapStateToProps(state, props) {

    const { list: route_list } = state.routes.all;
    const { data, error, loading } = state.branches.current;

	return {
        data: data,
        error: error,
        loading: loading,
        route_list: route_list
	};
}

export default connect(mapStateToProps)(withRouter(Details));
